'use strict';

const { initRecommandationSlider } = require('../carousel');
const { tippyTooltip } = require('../tooltipTippy');
var wishlistPage = $('.wishlist-landing-page');
var wishlistAddAllToCartBtn = $('.js-wishlist-add-all-to-cart');

const updateProductTilesWithCartData = (data, tileClass, productClass) => {
    var updateQuantityUrl = data.actionUrls ? data.actionUrls.updateQuantityUrl : '';
    var removeProductLineItemUrl = data.actionUrls ? data.actionUrls.removeProductLineItemUrl : '';
    $(`.${tileClass}.in-cart`).addClass('updating');
    data.items.forEach((item) => {
        var pid = item.id;
        var uuid = item.UUID;
        var maxQty = item.quantityOptions && item.quantityOptions.maxOrderQuantity ? item.quantityOptions.maxOrderQuantity : 1;
        var minQty = item.quantityOptions && item.quantityOptions.minOrderQuantity ? item.quantityOptions.minOrderQuantity : 1;
        var qty = item.quantity;
        var $productTile = $(`.${productClass}[data-pid=${pid}] .${tileClass}`);
        if ($productTile.length > 0) {
            $productTile.addClass('in-cart').removeClass('updating');
            var $quantitySelectorWrapper = $productTile.find('.update-quantity-wrapper');
            var $quantityInput = $quantitySelectorWrapper.find('input.quantity');
            $quantityInput.attr('data-uuid', uuid);
            $quantityInput.attr('data-pid', pid);
            $quantityInput.attr('data-action', updateQuantityUrl);
            $quantityInput.attr('data-remove', removeProductLineItemUrl);
            $quantityInput.attr('data-pre-select-qty', qty);
            $quantityInput.attr('data-min-qty', minQty);
            $quantityInput.attr('data-max-qty', maxQty);
            $quantityInput.attr('id', `quantity-${uuid}`);
            $quantityInput.attr('name', `quantity-${uuid}`);
            $quantityInput.val(qty);

            if (qty >= maxQty) {
                $productTile.addClass('has-max-qty');
            } else {
                $productTile.removeClass('has-max-qty');
            }
        }
    });

    $(`.${tileClass}.in-cart.updating`)
        .removeClass('updating in-cart has-max-qty has-min-qty')
        .find('input.quantity')
        .removeAttr('id name data-uuid data-pid data-action data-remove data-pre-select-qty data-min-qty data-max-qty')
        .removeData();

    var $quantityForm = $(`input.quantity[data-uuid="${data.pliUUID}"]`).parent();
    $quantityForm.removeClass('disabled');
};

const removeProductFromCart = ($input, tileClass, productClass) => {
    return new Promise((resolve, reject) => {
        const removeProductLineItemUrl = $input.attr('data-remove');
        const uuid = $input.attr('data-uuid');
        const pid = $input.attr('data-pid');
        const quantity = $input.attr('data-quantity');

        if (window.updateQuantityTrigger && window.updateQuantityTrigger[pid]) {
            clearTimeout(window.updateQuantityTrigger[pid]);
        }

        const $body = $('body');
        $body.trigger('cart:beforeUpdate');

        $.ajax({
            url: removeProductLineItemUrl,
            method: 'GET',
            data: {
                uuid: uuid,
                pid: pid,
                quantity: quantity
            },
            /**
             * Success Handler
             * @param {*} data Response from server
             */
            success: function (data) {
                if (data.basket.items.length === 0 && $('.cart-page').length > 0) {
                    // Reload if empty cart
                    window.location.reload();
                    return;
                }

                // Remove product card
                $input.closest('.cf-card__wrapper').remove();

                updateProductTilesWithCartData(data.basket, tileClass, productClass);
                $body.trigger('cart:update', data.basket);
                $body.trigger('product:afterRemoveFromCart', data);
                if (wishlistPage.length) {
                    wishlistAddAllToCartBtn.removeAttr('disabled');
                }

                const $accordionItem = $input.closest('.accordion-item');

                // Update Group Items count
                let newCount = $accordionItem.find('.cf-card__wrapper').length;
                if (newCount > 0) {
                    $accordionItem.find('.accordion-heading .count').text(newCount);
                } else {
                    // Remove group
                    $accordionItem.remove();
                }

                resolve(data);
            },
            /**
             * Error Handler
             * @param {*} data Response from server
             */
            error: function (data) {
                reject(data);
            }
        });
    });
};

const updateAddToCartButtons = () => {
    var getCartURL = $('#get-cart-url').val();
    var $productGrid = $('.product-grid');

    if (!getCartURL || $('.js-product-tile').length === 0) {
        return;
    }

    $productGrid.removeClass('ready');

    $.ajax({
        url: getCartURL,
        method: 'GET',
        data: {},
        /**
         * Success Handler
         * @param {*} data Response from server
         */
        success: function (data) {
            updateProductTilesWithCartData(data, 'js-product-tile', 'product');
            $productGrid.addClass('ready');
            $('body').trigger('tile:afterAddToCartBtnUpdate');
        },
        /**
         * Error Handler
         * @param {*} error Error object
         */
        error: function () {
            $productGrid.addClass('ready');
        }
    });
};

const updateProductsWishlistStatus = (isPDP) => {
    const getWishlistURL = $('#get-wishlist-url').val();
    if (!getWishlistURL || $('.js-product-wishlist-btn').length === 0) {
        return;
    }

    const productsIDs = [];

    const productCls = isPDP ? '.product-detail' : '.product';
    $(productCls).each((index, element) => {
        const $element = $(element);
        const pid = $element.attr('data-pid');
        if (productsIDs.indexOf(pid) < 0) {
            productsIDs.push(pid);
        }
    });

    if (productsIDs.length === 0) {
        return;
    }

    $.ajax({
        url: getWishlistURL,
        method: 'GET',
        /**
         * Success Handler
         * @param {*} data Response from server
         */
        success: function (data) {
            if (data.success && data.productIDs) {
                productsIDs.forEach((pid) => {
                    let $wishlistBtn = $(`.js-product-wishlist-btn[data-pid=${pid}]`);
                    if (data.productIDs.includes(pid)) {
                        $wishlistBtn.find('i').removeClass('heart').addClass('heart-active');
                    }
                });
            }
        }
    });
};

const showReplacementProductsModal = (productID, productName = '') => {
    var $modal = $('#replacementProductsModal');
    var url = $modal.data('url');

    if (!url) {
        return;
    }

    $.ajax({
        url: url,
        method: 'GET',
        data: {
            pid: productID,
            srule: 'price-low-high',
            name: productName
        },
        /**
         * Success Handler
         * @param {*} data Response from server
         */
        success: function (data) {
            $modal.find('.modal-content').empty().append(data);
            initRecommandationSlider();
            $modal.detach().appendTo('body').modal('show');
            $('.product .add-to-cart-wrapper').removeClass('d-none');
            updateAddToCartButtons();
            // find the elements
            tippyTooltip();
            updateProductsWishlistStatus();
        }
    });
};

/**
 * Render the replacement products modal
 * @param {Event} event - source event
 * @param {string} productClass - product class
 */
const getReplacementProducts = (event, productClass) => {
    event.preventDefault();
    const $targetElement = $(event.target);
    const $productElement = $targetElement.closest(`.${productClass}`);
    const productID = $productElement.attr('data-pid');
    const productName = $productElement.attr('data-title');

    showReplacementProductsModal(productID, productName);
};

/**
 * Get products availability by product IDs and call the success callback
 * Makes the logic reusable for different components
 * @param {Array} productsIDs - Array of product IDs
 * @param {Function} successCallback - Callback function to handle the response
 * @returns {void}
 */
const getProductsAvailability = (productsIDs, successCallback) => {
    const checkAvailabilityURL = $('#get-availability-url').val();
    if (!checkAvailabilityURL || productsIDs.length === 0) {
        return;
    }

    $.ajax({
        url: checkAvailabilityURL,
        method: 'GET',
        data: {
            pids: productsIDs.join(',')
        },
        /**
         * Success Handler
         * @param {Object} data Response from server
         */
        success: successCallback
    });
};

/**
 * Fetches the availability information for the product tiles
 * @returns {void}
 */
const getProductTilesAvailabilityInformation = () => {
    if ($('.product-tile').length === 0) {
        return;
    }

    const successCallback = (data) => {
        $('.product-tile.unavailable').removeClass('unavailable');
        if (data.results) {
            data.results.forEach((availabilityData) => {
                if (availabilityData) {
                    const $productTile = $(`.product[data-pid=${availabilityData.pid}] .product-tile`);
                    if (availabilityData.ats <= 0) {
                        $productTile.addClass('unavailable');
                    }
                    $productTile.find('.add-to-cart-wrapper').removeClass('d-none'); // show CTA wrapper once availability fetched
                }
            });
        }
    };

    const productsIDs = [];
    $('.product').each((index, element) => {
        const $element = $(element);
        const pid = $element.attr('data-pid');
        if (productsIDs.indexOf(pid) < 0) {
            productsIDs.push(pid);
        }
    });

    getProductsAvailability(productsIDs, successCallback);
};

const fetchQstringParam = (param) => {
    const queryString = $('.page').attr('data-querystring');
    const queryParams = new URLSearchParams(queryString);

    return queryParams.get(param);
};

const getProductPosition = ($selector, isTile) => {
    let itemListId = $selector && $selector.attr('data-item-list-id');
    let itemListName = $selector && $selector.attr('data-item-list-name');

    let productId, categoryName, parentCategory, categoryId, searchTerm;

    if (!itemListName || itemListName === 'null') {
        const pageType = $('.page').attr('data-page-type');
        switch (pageType) {
            case 'home':
                itemListName = 'home carousel';
                break;
            case 'pdp':
                productId = $('.product-detail').attr('data-pid');
                itemListName = 'product details';
                itemListId = productId;

                if (isTile) {
                    itemListName = 'pdp carousel';
                    itemListId = `pdp carousel ${productId}`;
                }

                break;
            case 'plp':
                categoryName = $('#current-category-id').attr('value');
                parentCategory = $('#current-level-0-category-id').attr('value');

                categoryId = fetchQstringParam('cgid');

                itemListName = 'category';
                itemListId = `category ${categoryId}`;

                if (parentCategory && parentCategory === 'brands') {
                    itemListId = `brand ${categoryName}`;
                    itemListName = 'brand';
                }

                break;
            case 'searchResults':
                searchTerm = fetchQstringParam('q');
                itemListId = `search results ${searchTerm}`;
                itemListName = 'search results';
                break;
            case 'wishlist':
                itemListId = undefined;
                itemListName = 'user product list';
                break;
            case 'frequentlyPurchased':
                itemListId = undefined;
                itemListName = 'frequently purchased list';
                break;
            default:
                itemListId = undefined;
                itemListName = undefined;
                break;
        }
    }

    return {
        itemListId: itemListId,
        itemListName: itemListName
    };
};

module.exports = {
    updateProductTilesWithCartData: updateProductTilesWithCartData,
    removeProductFromCart: removeProductFromCart,
    getReplacementProducts: getReplacementProducts,
    updateAddToCartButtons: updateAddToCartButtons,
    getProductTilesAvailabilityInformation: getProductTilesAvailabilityInformation,
    updateProductsWishlistStatus: updateProductsWishlistStatus,
    showReplacementProductsModal: showReplacementProductsModal,
    getProductsAvailability: getProductsAvailability,
    getProductPosition: getProductPosition,
    fetchQstringParam
};
