'use strict';

const { tippyTooltip } = require('./tooltipTippy');

const initRecommandationSlider = ($selector, sliderOptions, validationOptions) => {
    const { getProductTilesAvailabilityInformation, updateProductsWishlistStatus, updateAddToCartButtons } = require('./product/productUtils');

    let sliders = $('.recommandation-carousel');
    if ($selector) {
        sliders = $selector.find('.recommandation-carousel');
    }

    if (!sliders.length) return;

    sliders.each(function () {
        getProductTilesAvailabilityInformation();
        updateProductsWishlistStatus();

        const slider = $(this);
        setTimeout(() => {
            const config = {
                className: 'recommandation-carousel',
                margin: 0,
                nav: true,
                dots: false,
                autoWidth: true,
                lazyLoad: true,
                slideBy: 2,
                navText: ['<i class="icon icon-arrow-left-circle"></i>', '<i class="icon icon-arrow-right-circle"></i>'],
                responsive: {
                    0: {
                        items: 1,
                        slideBy: 1,
                        nav: false
                    },
                    768: {
                        items: 2,
                        slideBy: 2,
                        nav: false
                    },
                    1024: {
                        items: 2,
                        slideBy: 2,
                        nav: true
                    }
                },
                onInitialized: validateCarouselItems // eslint-disable-line no-use-before-define
            };

            if (sliderOptions) {
                Object.keys(sliderOptions).forEach((option) => {
                    config[option] = sliderOptions[option];
                });
            }

            slider.owlCarousel(config);
        }, 10);
        /**
         * Callback function after carousel initialized
         * @param {Event} event - Event Obj
         */
        function validateCarouselItems(event) {
            const $targetCarousel = $(event.target);
            let deletedItemsCount = 0;
            $targetCarousel.find('.owl-item').each(function (index, item) {
                // Remove empty/invalid product carousel items
                let $productItemWrapper = $(item).find('.experience-commerce_assets-productTile');
                if ($productItemWrapper.length > 0 && $productItemWrapper.find('.product').length === 0) {
                    setTimeout(function () {
                        $targetCarousel.trigger('remove.owl.carousel', index - deletedItemsCount);
                        $targetCarousel.trigger('refresh.owl.carousel');
                        deletedItemsCount++;
                    }, 0);
                }
            });
            updateAddToCartButtons();
            tippyTooltip();

            if (validationOptions && validationOptions.hideSkeletonAnimation) $('body').trigger('custom:carouselSwitcherDisplay', [validationOptions.hideSkeletonAnimation]);
        }
    });
};

module.exports = {
    methods: {
        initRecommandationSlider: initRecommandationSlider
    },
    initRecommandationSlider: () => {
        $(document).ready(() => initRecommandationSlider());
    },
    initMutationObserver: () => {
        const parentContainers = document.querySelectorAll('.recommendations div[id^="cq_"]');
        if (!parentContainers.length) return;

        const config = {
            childList: true,
            subtree: true
        };

        for (let i = 0; i < parentContainers.length; i++) {
            const parentContainer = parentContainers[i];

            const callback = (mutationList, observer) => {
                mutationList.forEach((mutationEl) => {
                    initRecommandationSlider($(mutationEl.target));
                });

                observer.disconnect();
            };

            const observer = new MutationObserver(callback);
            observer.observe(parentContainer, config);
        }
    }
};
