'use strict';

const tippy = require('tippy.js').default;

const tippyTooltip = () => {
    /**
     * Initialize Tippy tooltips for elements with specific data attributes.
     *
     * @param {string} selector - The CSS selector for the elements to apply tooltips to.
     * @param {Object} config - Configuration options for the tooltips.
     */
    tippy(".promo-tag[data-template='']:not([data-template='']), .promo-tag[data-tippy-initialized='']:not([data-tippy-initialized='true'])", {
        /**
         * Get the content for the tooltip.
         *
         * @param {HTMLElement} reference - The reference element for the tooltip.
         * @returns {string} The HTML content for the tooltip.
         */
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true,
        interactive: true,
        arrow: true,
        theme: 'light-border',
        placement: 'bottom',
        popperOptions: {
            strategy: 'fixed'
        },
        /**
         * Callback function when the tooltip is shown.
         *
         * @param {TippyInstance} instance - The Tippy instance.
         */
        onShow(instance) {
            instance.popper.querySelector('#tooltip-close').addEventListener('click', () => {
                instance.hide();
            });
        },
        /**
         * Callback function when the tooltip is hidden.
         *
         * @param {TippyInstance} instance - The Tippy instance.
         */
        onHide(instance) {
            instance.popper.querySelector('#tooltip-close').removeEventListener('click', () => {
                instance.hide();
            });
        },
        /**
         * Callback function when the tooltip instance is created.
         *
         * @param {TippyInstance} instance - The Tippy instance.
         */
        onCreate(instance) {
            // Mark the element as initialized
            instance.reference.setAttribute('data-tippy-initialized', 'true');
        }
    });

    tippy('.allow-replacement-tooltip', {
        /**
         *
         * @param {object} reference - The DOM element that the tooltip is attached to
         * @returns {string} message
         */
        content(reference) {
            const message = reference.getAttribute('data-tooltip');
            return message;
        },
        allowHTML: true,
        interactive: true,
        arrow: true,
        theme: 'light-border',
        placement: 'top',
        trigger: 'click',
        popperOptions: {
            strategy: 'fixed'
        }
    });

    tippy('.fees-tooltip', {
        /**
         *
         * @param {object} reference - The DOM element that the tooltip is attached to
         * @returns {string} message
         */
        content(reference) {
            const message = reference.getAttribute('data-tooltip');
            return message;
        },
        allowHTML: true,
        interactive: true,
        arrow: true,
        theme: 'light-border',
        placement: 'top',
        trigger: 'click',
        popperOptions: {
            strategy: 'fixed'
        }
    });
};

module.exports = {
    tippyTooltip: tippyTooltip
};
